import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ADMIN_SUBDOMAIN } from '@remberg/global/common/core';
import {
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  EMAIL_VALIDATION_REGEX,
  LocalStorageKeys,
  LogService,
  MainRoutes,
  getStringID,
} from '@remberg/global/ui';
import {
  AppStateService,
  DeviceInfoService,
  GlobalActions,
  GlobalSelectors,
  NavigationalActions,
  RootGlobalState,
  RouterSelectors,
  TenantService,
  handleIamForbiddenMessage,
} from '@remberg/ui-core/core';
import { AccessPopupComponent, AccessPopupData } from '@remberg/ui-core/shared-main';
import { RembergUserLoginParams, RembergUsersService } from '@remberg/users/ui/clients';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, firstValueFrom, map, take } from 'rxjs';

const ADMIN_WORKSPACE_NAME = 'Admin';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  protected loginFormGroup = this._formBuilder.group({
    email: [''],
    password: [''],
    twoFactorToken: [''],
  });

  protected loginFailedNoInternet = false;
  protected twoFactorCheck = false;
  protected workspaceName = '';
  protected isAtAdminSubdomain = false;
  protected loginSent = false;

  protected readonly translations = {
    login: $localize`:@@logIn:Log in`,
    confirm: $localize`:@@confirm:Confirm`,
  };

  protected readonly isOnline$ = this.connectivityService.connection$;
  protected readonly isIonic$ = this.store.select(GlobalSelectors.selectIsIonic);

  private popupOpen = false;

  private credentials: Omit<RembergUserLoginParams, 'device' | 'mobileDevice'> = {
    email: '',
    password: '',
    tenantId: undefined,
    twoFactorToken: undefined,
  };

  @ViewChild('passwordInput', { static: false }) protected passwordInputRef?: ElementRef;

  constructor(
    private readonly route: ActivatedRoute,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly dialog: MatDialog,
    private readonly rembergUsersService: RembergUsersService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly logger: LogService,
    private readonly tenantService: TenantService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly appState: AppStateService,
    private readonly store: Store<RootGlobalState>,
    private readonly actions$: Actions,
    private readonly deviceInfoService: DeviceInfoService,
  ) {}

  public ngOnInit(): void {
    this.init();
  }

  /** Function is needed to handle iOS autofill case correctly. */
  protected async passwordNativeChange(password: string): Promise<void> {
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    if (isIonic) {
      this.logger.debug()('Register native password change.');
      this.loginFormGroup.patchValue({ password });
    }
  }

  /** Function is needed to handle iOS autofill case correctly. */
  protected async emailNativeChange(email: string): Promise<void> {
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));
    if (isIonic) {
      this.logger.debug()('Register native email change.');
      this.loginFormGroup.patchValue({ email });
    }
  }

  protected abortLogin(): void {
    this.twoFactorCheck = false;
    this.loginFailedNoInternet = true;
    this.loginFormGroup.patchValue({ password: '', twoFactorToken: '' });
  }

  protected onLoginClick(event: MouseEvent | KeyboardEvent): void {
    this.validateForm();

    if (!this.loginFormGroup.valid) {
      this.logger.warn()('Login FormGroup is not valid yet.');
      return;
    }

    const isClickOrEnter = event.type === 'click' || (event as KeyboardEvent).keyCode === 13;
    if (this.loginSent || !isClickOrEnter) {
      return;
    }

    this.login();
  }

  protected async moveToResetPasswordPage(email: string): Promise<void> {
    const isPortal = await firstValueFrom(this.store.select(RouterSelectors.selectIsPortalRoute));
    const portalSegment = isPortal ? 'portal/' : '';
    this.router.navigate([`/${portalSegment}${MainRoutes.FORGOT}`], {
      queryParams: { passwordExpired: email },
      queryParamsHandling: 'merge',
    });
  }

  protected async openAccessPopup(): Promise<void> {
    if (this.popupOpen || !this.credentials.tenantId) {
      return;
    }
    this.popupOpen = true;
    this.cdRef.markForCheck();

    try {
      const tenant = await firstValueFrom(
        this.tenantService.getPublicById(this.credentials.tenantId),
      );

      const isPortal = await firstValueFrom(this.store.select(RouterSelectors.selectIsPortalRoute));

      const data: AccessPopupData = {
        isRegistrationOnly: true,
        tenantId: getStringID(this.credentials.tenantId),
        shouldNavigateToLoginOnBackClick: true,
        workspaceName: tenant.theme.workspaceName,
        isContact: isPortal,
      };

      const dialogRef = this.dialog.open(AccessPopupComponent, {
        panelClass: ['rb-dialog-md', 'confirmation'],
        data,
      });

      await firstValueFrom(dialogRef.afterClosed());
    } catch (error) {
      this.logger.error()(error);
      this.toastr.error($localize`:@@somethingWentWrong: Something went wrong`);
    }

    this.popupOpen = false;
    this.cdRef.markForCheck();
  }

  protected togglePasswordVisibility(): void {
    if (!this.passwordInputRef) {
      return;
    }
    if (this.passwordInputRef.nativeElement.type === 'password') {
      this.passwordInputRef.nativeElement.type = 'text';
    } else {
      this.passwordInputRef.nativeElement.type = 'password';
    }
  }

  protected async onMainDomainNavigation(): Promise<void> {
    const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));

    if (isIonic) {
      this.store.dispatch(GlobalActions.setTenantPublic({ tenantPublic: undefined }));
      await this.router.navigate(['welcome']);
    } else {
      this.store.dispatch(NavigationalActions.goToApplicationRoot({}));
    }
  }

  private async init(): Promise<void> {
    await this.appState.getReadyState();

    this.isAtAdminSubdomain = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsAtAdminSubdomain),
    );
    const isAdminSubdomainConfigured =
      this.appState.getValue(LocalStorageKeys.SUBDOMAIN) === ADMIN_SUBDOMAIN;

    if (this.isAtAdminSubdomain || isAdminSubdomainConfigured) {
      this.credentials.subdomain = ADMIN_SUBDOMAIN;
      this.workspaceName = ADMIN_WORKSPACE_NAME;
      this.cdRef.markForCheck();
      return;
    }

    const tenantId = await firstValueFrom(this.store.select(GlobalSelectors.selectTenantId));

    if (tenantId) {
      this.credentials.tenantId = tenantId;
      const theme = await firstValueFrom(this.store.select(GlobalSelectors.selectTheme));
      this.workspaceName = theme?.workspaceName ?? '';
      this.cdRef.markForCheck();
      return;
    }

    await this.handleInitialSubdomainRouting();
  }

  private async handleInitialSubdomainRouting(): Promise<void> {
    const [isSubdomainRoutingActive, isAtPreviewDomain] = await firstValueFrom(
      combineLatest([
        this.store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
        this.store.select(GlobalSelectors.selectIsAtPreviewDomain),
      ]),
    );
    if (!isSubdomainRoutingActive && !isAtPreviewDomain) {
      this.store.dispatch(NavigationalActions.goToApplicationRoot({}));
    } else {
      this.store.dispatch(GlobalActions.setTenantPublic({ tenantPublic: undefined }));
      await this.router.navigate(['welcome']);
    }
  }

  private validateForm(): void {
    const emailControl = this.loginFormGroup.get('email');
    if (!emailControl?.value) {
      emailControl?.setErrors({ required: true });
    } else if (!EMAIL_VALIDATION_REGEX.test(emailControl?.value?.trim())) {
      emailControl?.setErrors({ pattern: true });
    }

    const passwordControl = this.loginFormGroup.get('password');
    if (!passwordControl?.value) {
      passwordControl?.setErrors({ required: true });
    }
    this.loginFormGroup.markAllAsTouched();
    this.cdRef.detectChanges();
  }

  private async login(): Promise<void> {
    this.logger.debug()('Logging you in...');

    this.credentials = {
      ...this.credentials,
      email: this.loginFormGroup.value.email.toLowerCase().trim(),
      password: this.loginFormGroup.value.password,
    };

    this.loginSent = true;
    this.loginFormGroup.disable();
    if (this.twoFactorCheck) {
      this.credentials = {
        ...this.credentials,
        twoFactorToken: this.loginFormGroup.value.twoFactorToken,
      };
    }
    this.cdRef.markForCheck();

    try {
      const isIonic = await firstValueFrom(this.store.select(GlobalSelectors.selectIsIonic));

      const params: RembergUserLoginParams = {
        ...this.credentials,
        device: this.deviceInfoService.getDeviceIdSync(),
        mobileDevice: isIonic,
      };

      const data = await firstValueFrom(this.rembergUsersService.login(params));
      await this.registerSessionInfoOrThrow(data.token);
      this.loginFormGroup.patchValue({ password: '' });

      if (data.isPasswordAboutToExpire) {
        this.router.navigate(['/login', 'password'], { queryParamsHandling: 'merge' });
        return;
      }

      // navigate towards the correct destination
      const target = this.route.snapshot.queryParamMap.get('target');
      if (target && target !== '/' && target !== '/login') {
        this.logger.debug()('Moving to targeted page: ' + target);
        this.router.navigateByUrl(target);
        return;
      }

      this.store.dispatch(NavigationalActions.goToAuthenticatedDefaultPage());
      this.cdRef.markForCheck();
    } catch (error: unknown) {
      this.loginSent = false;
      this.loginFormGroup.enable();
      this.loginFormGroup.patchValue({ password: '' });
      void this.handleLoginError(error);
      this.cdRef.markForCheck();
    }
  }

  private async registerSessionInfoOrThrow(token: string): Promise<void> {
    this.store.dispatch(GlobalActions.startRegisterSessionInfoLogin({ token }));

    // Wait until login is complete or failed
    await firstValueFrom(
      this.actions$.pipe(
        ofType(GlobalActions.completeRegisterSessionInfo, GlobalActions.registerSessionInfoError),
        take(1),
        map((action) => {
          if (action.type === GlobalActions.registerSessionInfoError.type) {
            throw new Error(action.error);
          }
        }),
      ),
    );
  }

  private async handleLoginError(error: unknown): Promise<void> {
    this.logger.error()(error);
    if (error instanceof HttpErrorResponse) {
      const { statusCode, message } = error.error;
      switch (statusCode) {
        case 400:
          this.toastr.error($localize`:@@somethingWentWrong: Something went wrong`);
          break;
        case 403: {
          const isPortal = await firstValueFrom(
            this.store.select(RouterSelectors.selectIsPortalRoute),
          );
          handleIamForbiddenMessage({
            message,
            toastr: this.toastr,
            router: this.router,
            isPortal,
            email: this.loginFormGroup.get('email')?.value,
          });
          break;
        }
        case 404:
          this.loginFormGroup.get('password')?.setErrors({ incorrectCredentials: true });
          break;
        case 406:
          this.twoFactorCheck = true;
          this.loginFormGroup.patchValue({ password: this.credentials.password });
          this.toastr.info(
            $localize`:@@pleaseEnterTheTwoFactorToken: Please enter the two-factor token.`,
          );
          break;
        case 500:
          this.toastr.error(
            $localize`:@@loginServerErrorDotPleaseTryAgainLater!: Login Server Error. Please try again later!`,
          );
          break;
        case 0:
          this.loginFailedNoInternet = true;
          break;
      }
    } else {
      this.loginFailedNoInternet = true;
    }
  }
}
